<template>
  <b-card>
    <div>
      <validation-observer ref="accountRules" tag="form">
        <b-form class="mt-1" @submit.prevent>
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Master Keuangan
              </h5>
            </b-col>
            <b-col md="6">
              <b-form-group label="Tahun Ajaran" label-for="nip">
                <validation-provider #default="{ errors }" name="nip" rules="required">
                  <b-form-input id="nip" v-model="dataParent.nip" type="number"
                    :state="errors.length > 0 ? false : null" placeholder="Tahun Ajaran" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Tingkat" label-for="name">
                <validation-provider #default="{ errors }" name="name" rules="required">
                  <b-form-input id="name" v-model="dataParent.name" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Tingkat" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Saldo Awal SPP" label-for="nickname">
                <validation-provider #default="{ errors }" name="nickname" rules="required">
                  <b-form-input id="nickname" v-model="dataParent.nickname" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Saldo Awal SPP" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Tunggakan Awal SPP" label-for="first_title">
                <validation-provider #default="{ errors }" name="first_title" rules="required">
                  <b-form-input id="first_title" v-model="dataParent.first_title" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Tunggakan Awal SPP" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Nilai SPP" label-for="last_title">
                <validation-provider #default="{ errors }" name="last_title" rules="required">
                  <b-form-input id="last_title" v-model="dataParent.last_title" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Nilai SPP" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>              
              <b-form-group label="Keterangan" label-for="birth_place">
                <validation-provider #default="{ errors }" name="birth_place" rules="required">
                  <b-form-input id="birth_place" v-model="dataParent.birth_place" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Keterangan" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>              
            </b-col>           
          </b-row>
          <b-row class="text-right">
            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
                @click="formSubmitted">
                Submit
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                @click="() => $router.go(-1)">
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm, BButton,
} from 'bootstrap-vue'
import { required, email, size } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'
// import { codeIcon } from './code'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BButton,
  },
  data() {
    return {
      required,
      email,
      size,
      dataCountries,
      dataProvinces,
      dataCities,
      dataDistrics,
      dataSubDistrics,
      dataParent: {
        nip: '',
        name: '',
        nickname: '',
        first_title: '',
        last_title: '',
        gender: '',
        birth_place: '',
        birth_date: '',
        education_level: '',
        educational_institution: '',
        ktp_number: '',
        religion: '',
        ethnic: '',
        nationality: '',
        marital_status: '',
        is_active: '',
        type: '',
        province: '',
        city: '',
        district: '',
        subdistrict: '',
        rw: '',
        rt: '',
        address: '',
        phone_1: '',
        phone_2: '',
        whatsapp: '',
      },
      locations: {
        province: '',
        city: '',
        distric: '',
        subdistric: '',
      },
      dataGender: [
        { value: 'M', text: 'Laki-laki' },
        { value: 'F', text: 'Perempuan' },
      ],
      dataReligion: [
        { value: 1, text: 'Islam' },
        { value: 2, text: 'Protestan' },
        { value: 3, text: 'Katolik' },
        { value: 4, text: 'Hindu' },
        { value: 5, text: 'Buddha' },
        { value: 6, text: 'Khonghucu' },
      ],
      dataReadingQuran: [
        { value: 'lancar', text: 'Lancar' },
        { value: 'belum_lancar', text: 'Belum Lancar' },
        { value: 'belum_bisa', text: 'Belum Bisa' },
      ],
      dataEducation: [{ value: 1, text: 'SD/MI' }],
      dataParentStatus: [
        { value: '1', text: 'Ayah' },
        { value: '2', text: 'Ibu' },
        { value: '3', text: 'Wali' },
      ],
      dataParentEducation: [
        { value: '1', text: 'SD' },
        { value: '2', text: 'SMP' },
        { value: '3', text: 'SMA' },
        { value: '4', text: 'S1' },
        { value: '5', text: 'S2' },
        { value: '6', text: 'S3' },
      ],
      dataMutations: [
        { value: 'Y', text: 'Ya' },
        { value: 'N', text: 'Tidak' },
      ],
      dataMaritalStatus: [
        { value: 1, text: 'Menikah' },
        { value: 2, text: 'Belum Menikah' },
      ],
      dataIsActive: [
        { value: 1, text: 'Aktif' },
        { value: 0, text: 'Tidak Aktif' },
      ],
      dataClasss: [{ value: '4nvoeygeoxw3zpra', text: 'VII' }],
    }
  },
  computed: {
    filterCities() {
      return this.dataCities.filter(
        item => item.parent === this.locations.province.wilayah_id,
      )
    },
    filterDistrics() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.city.wilayah_id,
      )
    },
    filterSubDistrics() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.distric.wilayah_id,
      )
    },
    filterCitiesFather() {
      return this.dataCities.filter(
        item => item.parent === this.locations.father_province.wilayah_id,
      )
    },
    filterCitiesMother() {
      return this.dataCities.filter(
        item => item.parent === this.locations.mother_province.wilayah_id,
      )
    },
    filterDistricsFather() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.father_city.wilayah_id,
      )
    },
    filterDistricsMother() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.mother_city.wilayah_id,
      )
    },
    filterSubDistricsFather() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.father_distric.wilayah_id,
      )
    },
    filterSubDistricsMother() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.mother_distric.wilayah_id,
      )
    },
  },
  methods: {
    async formSubmitted() {
      try {
        const dataFormParent = new FormData()
        Object.keys(this.dataParent).forEach(key => {
          dataFormParent.append(key, this.dataParent[key])
        })
        dataFormParent.append('province', this.locations.province.name)
        dataFormParent.append('city', this.locations.city.name)
        dataFormParent.append('district', this.locations.distric.name)
        dataFormParent.append('subdistrict', this.locations.subdistric.name)
        await this.$http.post('/employees', dataFormParent)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Pegawai Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'data-pegawai' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Pegawai Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
