var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Master Keuangan ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tahun Ajaran","label-for":"nip"}},[_c('validation-provider',{attrs:{"name":"nip","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nip","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Tahun Ajaran"},model:{value:(_vm.dataParent.nip),callback:function ($$v) {_vm.$set(_vm.dataParent, "nip", $$v)},expression:"dataParent.nip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tingkat","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Tingkat"},model:{value:(_vm.dataParent.name),callback:function ($$v) {_vm.$set(_vm.dataParent, "name", $$v)},expression:"dataParent.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Saldo Awal SPP","label-for":"nickname"}},[_c('validation-provider',{attrs:{"name":"nickname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nickname","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Saldo Awal SPP"},model:{value:(_vm.dataParent.nickname),callback:function ($$v) {_vm.$set(_vm.dataParent, "nickname", $$v)},expression:"dataParent.nickname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tunggakan Awal SPP","label-for":"first_title"}},[_c('validation-provider',{attrs:{"name":"first_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_title","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Tunggakan Awal SPP"},model:{value:(_vm.dataParent.first_title),callback:function ($$v) {_vm.$set(_vm.dataParent, "first_title", $$v)},expression:"dataParent.first_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nilai SPP","label-for":"last_title"}},[_c('validation-provider',{attrs:{"name":"last_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_title","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Nilai SPP"},model:{value:(_vm.dataParent.last_title),callback:function ($$v) {_vm.$set(_vm.dataParent, "last_title", $$v)},expression:"dataParent.last_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keterangan","label-for":"birth_place"}},[_c('validation-provider',{attrs:{"name":"birth_place","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"birth_place","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan"},model:{value:(_vm.dataParent.birth_place),callback:function ($$v) {_vm.$set(_vm.dataParent, "birth_place", $$v)},expression:"dataParent.birth_place"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',{attrs:{"offset-md":"4"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.formSubmitted}},[_vm._v(" Submit ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Reset ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }